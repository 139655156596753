import React, { useState } from "react";
import styled, { DefaultTheme } from "styled-components";
import { rgba } from "polished";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import Container from '../../components/Layout/Container'
import { Title, Button, Section, Box, Text } from "../../components/Core";
import { ColorTypes, theme } from "../../utils";
import data from "../../../site/content/index.json";

const Card = styled(Box)`
  cursor: pointer;
  border: 1px solid #eae9f2;
  /* height: 100%; */
  overflow: hidden;

  &:hover {
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  }
`;

const CardButtonRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #eae9f2;
  background-color: ${({ theme }) => theme.colors["secondary"]};

  &:before {
    content: "";
    position: absolute;
    z-index: ${({ hover }) => (hover ? "0" : "-1")};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: ${({ hover }) => (hover ? "scaleX(1)" : "scaleX(0)")};
    transform-origin: 100% 50%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ${({ hover }) =>
      hover ? "cubic-bezier(0.52, 1.64, 0.37, 0.66)" : "ease-out;"};
    background: ${({ theme }) => theme.colors["primary"]};
  }
`;

const ButtonText = styled(Text)`
  z-index: 1;
`;

interface FeatureCardProps {
  color?: string;
  title?: string;
  iconName?: string;
  image?: string;
  points?: any;
  to?: string;
}

interface ThemeProps {
  theme: DefaultTheme;
  color: keyof ColorTypes;
  children: ReactNode;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  color = "primary",
  iconName,
  title,
  children,
  image,
  points,
  to,
  ...rest
}) => {
  const [hover, setHover] = useState(false);
  return (
    <Link to={to}>
      <Card
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        bg="light"
        py="0px"
        px="0px"
        borderRadius={10}
        {...rest}
      >
        <Box
          bg="#E0E0E0"
          style={{
            height: 300,
            width: "100%",
            background: `url(${image}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Box pt="20px" pb="30px" px="1.5rem">
          <Text
            color="heading"
            as="h3"
            fontSize={4}
            fontWeight={500}
            letterSpacing={-0.75}
            my={1}
          >
            {title}
          </Text>
          <Box mt={3}>
            {points &&
              points.map(({ text }) => (
                <Box className="d-flex align-items-center">
                  <i
                    className="fas fa-check"
                    style={{ color: theme.colors.primary, marginRight: 10 }}
                  ></i>
                  <Text fontSize={16}>{text}</Text>
                </Box>
              ))}
          </Box>
        </Box>
        <CardButtonRow hover={hover} className="d-flex align-items-end">
          <ButtonText
            color="light"
            fontSize="14px"
            fontWeight="600"
            lineHeight={1.75}
            className="d-flex align-items-end"
          >
            <span style={{ color: "#FFF" }}>READ MORE</span>
          </ButtonText>
        </CardButtonRow>
      </Card>
    </Link>
  );
};

const Feature = ({
  bg = "#f7f7fb",
  noTitle = false,
}: {
  bg?: string;
  noTitle?: boolean;
}) => (
  <>
    {/* <!-- Feature section --> */}
    <Section bg={bg} className="position-relative">
      <Container>
        {!noTitle && <Title>{data.features.title}</Title>}
        {/* <Row className="d-flex flex-wrap align-items-stretch justify-content-start"> */}
        <Row>
          {data.features.features.map(
            ({ text, title, image, points, to }, index) => (
              <Col lg="4" md="6" className="mb-5">
                <FeatureCard
                  key={index}
                  image={image}
                  points={points}
                  title={title}
                  to={to}
                >
                  {text}
                </FeatureCard>
              </Col>
            )
          )}
        </Row>
      </Container>
    </Section>
  </>
);

export default Feature;
